.typing-indicator {
    .bubble {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        padding: 8px 12px;
    }

    .dot {
        width: 8px;
        height: 8px;
        background-color: #333; // Change as per your design
        border-radius: 50%;
        margin: 0 2px;
        animation: typingAnimation 1.5s infinite ease-in-out;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes typingAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}
