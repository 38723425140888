.personas-list {
	min-width: 240px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 15px;
	background: #f7f7f7; // Soft background color for the whole list
	border-right: 1px solid #ddd; // Adds a subtle border to the right
	height: calc(100vh - 30px); // Full height of the viewport
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); // Soft shadow on the right edge
	z-index: 10; // Ensure it's above other content

	.persona {
		background-color: #fff; // Adjust for your color scheme
		border: 1px solid #e0e0e0;
		padding: 15px;
		border-radius: 12px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		transition:
			transform 0.3s ease,
			box-shadow 0.3s ease;
		display: flex;
		align-items: center;
		gap: 15px;

		.profile-image {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			object-fit: cover; // Ensures the image is nicely fitted
			border: 2px solid #e0e0e0; // Optional: Adds a border to the image
		}

		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
		}

		h3 {
			margin: 0 0 5px;
			color: #333; // Adjust for your color scheme
			font-size: 1.1rem;
			font-weight: 600; // Adjust the font weight as needed
		}

		p {
			margin: 0;
			font-size: 0.9rem;
			color: #666; // Adjust for your color scheme
			line-height: 1.4;
		}
	}
}

@media (max-width: 768px) {
	.personas-list {
		gap: 15px; // Reduced gap on mobile

		.persona {
			padding: 10px; // Reduced padding for each persona
		}
	}
}
