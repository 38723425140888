.chat-window {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 100%;
    height: 100vh;

    .message-list-wrapper {
        display: block;
        height: calc(100% - 90px);
        overflow-y: auto;
        padding-top: 1rem;
    }

    .message-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .message {
            display: flex;
            max-width: 80%;

            .message-content-wrapper {
                display: flex;
                flex-direction: column;
            }

            .message-content {
                padding: 10px 15px;
                margin: 5px;
                border-radius: 20px;
                text-align: left;

                &.user {
                    background-color: #daf8cb;
                }

                &.assistant {
                    background-color: #ebebeb;
                }
            }

            .username {
                font-size: 0.8rem;
                color: #6a5acd;
                margin-bottom: -4px;
            }

            &.user {
                align-self: flex-end;

                .username {
                    text-align: right;
                    margin-right: 1rem;
                }
            }

            &.assistant {
                align-self: flex-start;

                .username {
                    text-align: left;
                    margin-left: 1rem;
                }
            }

            .profile-image {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-top: 23px;
            }
        }
    }

    .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .toggle-personas-button {
            display: none;
            background-color: transparent;
            border-radius: 50%;
            border: 1px solid #009f6677;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
            margin-right: 1rem;
            cursor: pointer;
            background-color: #009f6677;
    
            &:hover {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
            }
    
            img {
                width: 30px;
                height: 30px;
            }
        }

        .input-field {
            width: 100%;
            padding: 10px 15px;
            border: 1px solid #ccc;
            border-radius: 20px;
            transition: border-color 0.3s;
            margin-right: 1rem;

            &:focus {
                outline: none;
                border-color: #6a5acd;
            }

            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }
        }

        .send-button {
            padding: 10px 20px;
            background-color: #6a5acd;
            color: white;
            border: none;
            border-radius: 20px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #5d4db2;
            }

            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }
        }
    }

    .empty-state {
        text-align: center;
        max-width: 300px;
        padding: 20px;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .profile-image {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 15px;
            border: 2px solid #e0e0e0;
        }

        h3 {
            margin-bottom: 10px;
            color: #333;
            font-size: 1.2rem;
        }

        p {
            color: #666;
            font-size: 0.95rem;
        }
    }
}

@media (max-width: 768px) {
    .chat-window {
        margin-left: 1rem;
        margin-right: 1rem;
        .input-wrapper {
            .toggle-personas-button {
                display: block;
            }
        }
    }
}
