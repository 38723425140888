.content-container {
	display: flex;
	flex-direction: row;
	position: relative;

}

@media (max-width: 768px) {
	.content-container {
		.personas-container {
			left: -100%; // Start off-screen
			position: fixed;
			top: 0;
			bottom: 0;
			width: 240px; // Same as min-width of .personas-list
			overflow-y: auto;
			background: white; // Or your chosen background color
			transition: left 0.3s ease;
			z-index: 10; // Ensure it's above other content

			&.open {
				left: 0; // Slide in
			}

			&.closed {
				left: -100%; // Slide out
			}

			width: 100vw; // Full width on mobile
		}
	}
}
